import React, { useState, useEffect } from "react";
import "./SearchMobile.scss";
import { useData } from "../../../contexts/DataContext";
import ScrollingBanner from "../../../components/ScrollBanner/ScrollBanner";
import SearchFilterSelector from "../../../components/SearchFilterSelector/SearchFilterSelector";
import LinePreview from "../../../components/ListedLinePreview/ListedLinePreviewMobile/ListedLinePreviewMobile";

function SearchMobile() {
  const { fetchData } = useData();
  const [picketts, setPicketts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadPicketts = async () => {
      try {
        setIsLoading(true);
        const response = await fetchData("/api/picketts");
        setPicketts(response.data);
      } catch (error) {
        console.error("Error fetching picketts:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadPicketts();
  }, [fetchData]);

  return (
      <div className="search-mobile">
        <div className="search-mobile-banner">
          <ScrollingBanner items={picketts.map((pickett) => pickett.name)} />
        </div>
        
        <div className="search-mobile-content">
          <div className="search-mobile-filter">
            <SearchFilterSelector />
          </div>
          
          <div className="search-mobile-results">
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              picketts.map((pickett, index) => (
                <LinePreview 
                  key={pickett.id || index}
                  title={pickett.name}
                  bio={pickett.bio}
                  participantCount={pickett.participants}
                  imageSource={pickett.coverPic}
                />
              ))
            )}
          </div>
        </div>

        <button 
          className="search-mobile-new-line-btn"
          aria-label="Create new line"
        >
          +
        </button>
      </div>
  );
}

export default SearchMobile;