import "./css/App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Layout from "./pages/Layout/Layout";
import Landing from "./pages/Landing/Landing";
import Profile from "./pages/Profile/Profile";
import AccountSettings from "./pages/AccountSettings/AccountSettings";
import ProfileMobile from "./pages/Profile/MobileProfile/MobileProfile";
import CreateLine from "./pages/CreateLine/CreateLine";
import NewUserSetup from "./pages/NewUserSetup/NewUserSetup";
import Line from './pages/Line/Line';
import RedirectTest from './pages/RedirectTest/RedirectTest';
import Redirect from './pages/Redirect/Redirect';
import Search from './pages/Search/Search';  
import SearchMobile from './pages/Search/SearchMobile/SearchMobile';
import Register from "./pages/Login/Register";
import { DataProvider } from './contexts/DataContext';
import { AuthProvider } from "./contexts/AuthContext";
import ErrorRedirect from './pages/ErrorRedirect/ErrorRedirect';
import Login from "./pages/Login/Login";
import { GoogleOAuthProvider } from "@react-oauth/google";


function App() {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <GoogleOAuthProvider clientId="750441779982-7ehh7gj76gf1brnhha655u01hasfanud.apps.googleusercontent.com"> 
        <AuthProvider>
            <DataProvider>
                <Router>
                    <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<Landing />} />
                        <Route path="/redirect" element={<Redirect />} />
                        <Route path="/redirect-test" element={<RedirectTest />} />
                        {width >= 500 && (
                        <Route path="/profile/:name" element={<Profile />} />
                        )}
                        {width < 500 && (
                        <Route path="/profile/:name" element={<ProfileMobile />} />
                        )}
                        <Route path="/account-settings" element={<AccountSettings />} />
                        <Route path="/line/:name" element={<Line />} />
                        <Route path="/create-line" element={<CreateLine />} />
                        {width >= 500 && (
                        <Route path="/search" element={<Search />} />
                        )}
                        {width < 500 && (
                        <Route path="/search" element={<SearchMobile />} />
                        )}
                        <Route path="/login" element={<Login />} />     
                        <Route path="/register" element={<Register />} />
                        <Route path="/*" element={<ErrorRedirect />} />
                        <Route path="/new-user-setup" element={<NewUserSetup />} />
                    </Route>
                    </Routes>
                </Router>
            </DataProvider>
        </AuthProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
