import React, { useState, useEffect } from "react";
import "./SearchFilterSelector.scss";
import SearchBar from "../SearchBar/SearchBar";
import MultiSelector from "../MultiSelector/MultiSelector";
import TagSelector from "../TagSelector/TagSelector";
import Switch from "../Switch/Switch";

const SearchFilterSelector = () => {
  // right now scale & type will both be passed as ints so we'll need a map or somthing on that backend to convert them to the actual type if we want that or could just leave as int's it's whatever tbh
  const [searchFilters, setSearchFilters] = useState({
    searchText: "",
    type: "",
    scale: "",
    participants: "more",
    startDate: "",
    endDate: "",
    selectedTags: []
  });

  const [selectedItems, setSelectedItems] = useState({});
  const [visibleItems, setVisibleItems] = useState({});

  const handleTypeChange = (newState) => {
    setSearchFilters(prevFilters => ({
      ...prevFilters,
      type: newState
    }));
  };

  const handleScaleChange = (newState) => {
    setSearchFilters(prevFilters => ({
      ...prevFilters,
      scale: newState
    }));
  };

  const handleParticipantsChange = (newState) => {
    setSearchFilters(prevFilters => ({
      ...prevFilters,
      participants: newState
    }));
  };

  const handleParticipantThresholdChange = (event) => {
    setSearchFilters(prevFilters => ({
      ...prevFilters,
      participantThreshold: event.target.value
    }));
  };

  const handleRangeStartChange = (event) => {
    setSearchFilters(prevFilters => ({
      ...prevFilters,
      startDate: event.target.value
    }));
  };

  const handleRangeEndChange = (event) => {
    setSearchFilters(prevFilters => ({
      ...prevFilters,
      endDate: event.target.value
    }));
  };

  const handleTagChange = (newState) => {
    setSearchFilters(prevFilters => ({
      ...prevFilters,
      selectedTags: newState || []
    }));
    return newState;
  };


  const handleSearch = (searchText) => {
    // creates a copy of the searchFilter values and only adds the vals that are currently toggled. Then "send them to the backend", for now just console.log need to replace tom.
    const activeFilters = {
        searchText: searchText
    };

    if (selectedItems.type) {
        activeFilters.type = searchFilters.type;
    }
    if (selectedItems.scale) {
        activeFilters.scale = searchFilters.scale;
    }
    if (selectedItems.participants) {
        activeFilters.participants = searchFilters.participants;
        if (searchFilters.participantThreshold) {
            activeFilters.participantThreshold = searchFilters.participantThreshold;
        }
    }
    if (selectedItems.range) {
        activeFilters.startDate = searchFilters.startDate;
        activeFilters.endDate = searchFilters.endDate;
    }
    if (selectedItems.tags) {
        activeFilters.selectedTags = searchFilters.selectedTags;
    }

    setSearchFilters(prevFilters => ({
        ...prevFilters,
        searchText: searchText
    }));

    console.log("Active search filters:", activeFilters);
    alert(JSON.stringify(activeFilters, null, 2));
};

  const handleSelectorChange = (newState) => {
    setSelectedItems(newState);
    console.log("MultiSelector state:", newState);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisibleItems(selectedItems);
    }, 50);
    return () => clearTimeout(timer);
  }, [selectedItems]);

  const tags = [
    "React",
    "JavaScript",
    "CSS",
    "HTML",
    "Node.js",
    "Express",
    "MongoDB",
    "GraphQL",
  ];

  return (
    <div className="search-filter-selector-container">
      <SearchBar onSearch={handleSearch} />
      <div className="temp-spacer"></div>

      <MultiSelector
        items={["type", "scale", "participants", "range", "tags"]}
        selectedItems={selectedItems}
        setSelectedItems={handleSelectorChange}
      />

      <div className={`filter-item ${visibleItems.type ? "show" : ""}`}>
        <div className="temp-spacer"></div>
        <Switch
          options={["all", "urgent", "current", "ended"]}
          onChange={handleTypeChange}
          defaultValue={searchFilters.type}
        />
      </div>

      <div className={`filter-item ${visibleItems.scale ? "show" : ""}`}>
        <div className="temp-spacer"></div>
        <Switch
          options={["city", "state", "country", "global"]}
          onChange={handleScaleChange}
          defaultValue={searchFilters.scale}
        />
      </div>

      <div className={`filter-item ${visibleItems.participants ? "show" : ""}`}>
        <div className="temp-spacer"></div>
        <Switch
          options={["more", "less"]}
          onChange={handleParticipantsChange}
          defaultValue={searchFilters.participants}
        />
        <input
          className="participant-threshold-input"
          type="number"
          onChange={handleParticipantThresholdChange}
          value={searchFilters.participantThreshold || ''}
        />
      </div>

      <div className={`filter-item ${visibleItems.range ? "show" : ""}`}>
        <div className="temp-spacer"></div>
        <div className="date-selector-container">
          <input
            className="search-year-range"
            type="date"
            onChange={handleRangeStartChange}
            value={searchFilters.startDate}
          />
          <input
            className="search-year-range"
            type="date"
            onChange={handleRangeEndChange}
            value={searchFilters.endDate}
          />
        </div>
      </div>

      <div className={`filter-item ${visibleItems.tags ? "show" : ""}`}>
        <div className="temp-spacer"></div>
        <TagSelector
          tags={tags}
          selectionMax={3}
          onChange={handleTagChange}
          initialSelection={searchFilters.selectedTags}
        />
      </div>
    </div>
  );
};

export default SearchFilterSelector;