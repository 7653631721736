import React, { useState, useEffect } from "react";
import "./MobileProfile.scss";
import { useData } from "../../../contexts/DataContext";

import NavBar from "../../../components/NavBar/NavBar.jsx";
import ScrollingBanner from "../../../components/ScrollBanner/ScrollBanner";
import LineViewer from "../../../components/LineViewer/LineViewer.jsx";
import ProfilePreview from "../../../components/ProfilePreview/ProfilePreview.jsx";
import PinnedLinePreviewer from "../../../components/PinnedLinePreviewer/PinnedLinePreviewer.jsx";
import UserStatDisplay from "../../../components/UserStatDisplay/MobileUserStatDisplay/MobileUserStatDisplay.jsx";

import testPFP from "../../../resources/johnK.jpg";

function MobileProfile() {
  const { fetchData } = useData();
  const [picketts, setPicketts] = useState([]);
  const [pinnedLine, setPinnedLine] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    fetchData("/api/picketts").then((response) => {
      if (response.success && response.data.length > 0) {
        setPicketts(response.data);
        setPinnedLine(response.data[0]);
      }
    });
    fetchData("/api/user").then((response) => {

      if(response.success) {
        setUser(response.data);
      }
    });
  }, [fetchData]);

  return (
    <div className="Profile">
      {/* need to make navbar component version for logged in vs not  */}
      <NavBar />
       {/* <ScrollingBanner items={picketts.map((pickett) => pickett.name)} /> */}

    
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <span className="temp-vert-aligner">
            <ProfilePreview
              imageSrc={testPFP}
              name="John Klaumf"
              description={
                "example bio."
              }
            />
            
            {user && (
            <UserStatDisplay
              followers={user.stats.followers}
              following={user.stats.following}
              lines={user.stats.lines}
              donations={`$${user.stats.donated.toFixed(2)}`}
              streak={user.streak}
              badges={"test"}
            />
            )}
          {pinnedLine && (
            <PinnedLinePreviewer
              title={pinnedLine.title}
              coverPic={pinnedLine.coverPic}
              bio={pinnedLine.bio}
              organizer={pinnedLine.organizer}
              organizerPFP={pinnedLine.organizerPFP}
              participants={pinnedLine.participants}
              site={pinnedLine.site}
            />
          )}
            <LineViewer lines={picketts}/>
    
        </span>
      </div>
    </div>
  );
}

export default MobileProfile;
