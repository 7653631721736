import React, { useState, useEffect } from "react";
import "./Search.scss";
import { useData } from "../../contexts/DataContext";

import NavBar from "../../components/NavBar/NavBar";
import ScrollingBanner from "../../components/ScrollBanner/ScrollBanner";
import Label from "../../components/Label/Label";
import SearchFilterSelector from "../../components/SearchFilterSelector/SearchFilterSelector";
import LinePreview from "../../components/ListedLinePreview/ListedLinePreview";

import tempPFPLineOwner from "../../resources/highResPFPTest.jpg";
import exampleLineCover from "../../resources/testLineCover.jpg";

function Search() {
  const { fetchData } = useData();
  const [picketts, setPicketts] = useState([]);

  useEffect(() => {
    fetchData("/api/picketts").then((response) => {
      setPicketts(response.data);
    });
  }, []);

  return (
    <div className="search page">
       <ScrollingBanner items={picketts.map((pickett) => pickett.name)} />
      
      <div className="search-content">
        <div className="search-filter">
          <SearchFilterSelector />
        </div>
        <div className="search-results">
          {picketts.map((picketts, index) => (
              <React.Fragment key={index}>
              <LinePreview 
                title={picketts.name}
                bio={picketts.bio}
                participantCount={picketts.participants}
                imageSource={picketts.coverPic}
              />
              <div className="searched-line-spacer-temp" style={{ marginBottom: "10px" }}></div>
            </React.Fragment>
          ))}
        </div>
      </div>
      {/* TO-DO: Add more clear of a call for them to make a line here, also add a state where when 
      there are no matching searches the button moves to middle and grows and asks them to 
      make a new line, anamation needs a bit mroe thought. 

      Also needs a loading anaimation unless it's v fast and possible an anaimation for them to like fade or drop in
       */}
      <div className="pinned-new-line-wrapper">+</div>
    </div>
  );
}

export default Search;